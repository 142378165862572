<template>
	<section class="container">
		<div class="row">
			<div class="col-auto mx-auto" v-if="$can('create', 'Company')">
				<router-link to="/company/add">
					<b-button class="mb-3">
						{{ $t('companyList.create') }}
					</b-button>
				</router-link>
			</div>
		</div>
		<div class="row">
			<template v-if="isAdvisorOrAdmin">
				<div class="col-auto pr-0">
						<InputCheck v-model="filterAdvisor" label="Filter virksomheder" />
					</div>
				<div class="col-auto pl-0 mt-1"><b>Rådgiver filtering</b></div>

				<div class="row">

					<div class="col">
						<InputDropdown v-model="selectedUser" :options="advisorsAndAdmins" :placeholder="'Rådgiver'" :disabled="!filterAdvisor" />
					</div>

					<div class="col-auto pr-0">
						<InputCheck v-model="primaryAdvisor" label="''"  :disabled="!filterAdvisor"/>
					</div>
					<div class="col-auto pl-0 mt-1">Primære rådgiver</div>
					<div class="col-auto pr-0">
						<InputCheck v-model="secondaryAdvisor" label="''"  :disabled="!filterAdvisor"/>
					</div>
					<div class="col-auto pl-0 mt-1">Sekundær rådgiver</div>
				</div>
			</template>
		</div>
	</br>
		<div class="row">
			<b-form-input class="input col-12" v-model="globalfilter" placeholder="Søg" />
			<div class="col-12">
				<BaseTable :items="companies" @currentPageChanged="currentPageChanged" @perPageChanged="perPageChanged" :fields="fields" :isBusy="isBusy">
					<template v-slot:cell(index)="data">
						{{ currentPage * perPage - perPage + data.index + 1 }}
					</template>
					<template v-slot:cell(companyInfo.name)="data">
						<router-link :to="{ name: 'Company', params: { companyId: data.item.id } }">
							{{ data.item.companyInfo.name }}
						</router-link>
					</template>
					<template v-slot:cell(employees)="row">
						<b-button size="sm" @click="row.toggleDetails" class="mr-2"> {{ row.item.employees.length }} Medarbejder </b-button>
					</template>
					<template #row-details="row">
						<div class="col-auto mx-auto" v-for="(employeeId, index) in row.item.employees" v-bind:key="employeeId">
							<router-link
								:to="{
									name: 'CompanyEmployeeInfo',
									params: {
										companyId: row.item.id,
										employeeId: employeeId,
									},
								}"
							>
								{{ index + 1 }}. {{ getUser(employeeId) }}
							</router-link>
						</div>
					</template>
				</BaseTable>
			</div>
		</div>
	</section>
</template>

<script>
import BaseTable from '@/modules/global/components/BaseTable'
import i18n from '@/plugins/i18n'
import Swal from 'sweetalert2'
import clientVersionMixin from '@/mixins/clientVersionMixin'
import InputDropdown from '@/modules/global/atomComponents/InputDropdown.vue'
import InputCheck from '@/modules/global/atomComponents/InputCheck.vue'

export default {
	name: 'CompanyList',
	components: {
		BaseTable,
		InputDropdown,
		InputCheck,
	},
	mixins: [clientVersionMixin],
	created() {
		this.selectedUser = this.$store.getters['auth/uid']
	},
	data() {
		return {
			currentPage: 1,
			perPage: 30,
			globalfilter: '',
			filterAdvisor: true,
			selectedUser: null,
			primaryAdvisor: true,
			secondaryAdvisor: false,
			fields: [
				{
					key: 'index',
					label: '',
				},
				{
					key: 'companyInfo.name',
					label: i18n.t('companyList.table.name'),
					sortable: true,
				},
				{
					key: 'companyInfo.cvr',
					label: i18n.t('companyList.table.cvr'),
					sortable: true,
				},
				{
					key: 'employees',
					label: i18n.t('companyList.table.employees'),
					sortable: true,
				},
			],
		}
	},
	async mounted() {
		this.$emit('updateLoader')
		await this.$store.dispatch('company/getCompanies') // Load data fra api+
		this.checkClientVersion()
	},
	methods: {
		currentPageChanged(page) {
			this.currentPage = page
		},
		perPageChanged(perPage) {
			this.perPage = perPage
		},
		onDelete(id) {
			Swal.fire({
				title: 'Slet virksomhed?',
				text: 'Vil du slette denne virksomhed? Du kan ikke fortryde dette.',
				icon: 'warning',
				showCancelButton: true,
				confirmButtonColor: '#3085d6',
				cancelButtonColor: '#d33',
				confirmButtonText: 'Ja overskriv!',
				showLoaderOnConfirm: true,
				preConfirm: async () => {
					try {
						await this.$store.dispatch('companyVuex/deleteCompany', id)
					} catch (error) {
						Swal.showValidationMessage(`Request failed: ${error}`)
					}
				},
			}).then(async (result) => {
				if (result.isConfirmed) {
					Swal.fire('Slettet!', 'Virksomheden er nu slettet', 'success')
				}
			})
		},
		getUser(employeeId) {
			var user = this.users.find((user) => user.id === employeeId)
			return user.fullName
		},
		setCookie() {
			console.log('setting cookie')
			this.$cookies.set('showCompanyList', this.filterAdvisor)
		},
	},
	computed: {
		companies() {
			var companies = this.$store.getters['company/companies']
			companies = companies.sort((a, b) => (a.companyInfo.name > b.companyInfo.name ? 1 : -1))
			return companies
				.filter((obj) => {
					if (this.globalfilter == '') {
						return true
					} else {
						return Object.keys(obj).some((key) => {
							try {
								var filtered = JSON.stringify(obj[key]).toLowerCase()
								return filtered.includes(this.globalfilter.toLowerCase())
							} catch (err) {
								console.log(key)
							}
						})
					}
				})
				.filter((obj) => {
					if (this.isAdvisorOrAdmin) {
						if (obj.companyInfo.name == 'Fratrædelse gruppe') {
							return true
						} else {
							if(!this.filterAdvisor){
								return true
							}
							if (this.selectedUser == null) {
								return true
							} else {
								return (this.primaryAdvisor && obj.companyAdvisors.primaryAdvisor == this.selectedUser) ||
       (this.secondaryAdvisor && obj.companyAdvisors.secondaryAdvisor == this.selectedUser);
							}
						}
					} else {
						return true
					}
				})
		},
		users() {
			return this.$store.getters['user/users']
		},
		advisorsAndAdmins() {
			return this.users
				.filter((user) => user.role.isAdmin || user.role.isAdvisor)
				.map((user) => {
					return {
						value: user.id,
						text: user.fullName,
					}
				})
				.sort((a, b) => (a.text > b.text ? 1 : -1))
		},
		isBusy() {
			return !this.$store.getters['company/isReady']
		},
		isAdvisorOrAdmin() {
			return this.$store.getters['auth/isAdmin'] || this.$store.getters['auth/isAdvisor']
		},
	},
}
</script>

<style lang="scss"></style>
